var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"my-app-bar-style",attrs:{"id":"app-bar","absolute":"","app":"","color":"#CFD8DC","flat":"","height":"75"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[(_vm.value)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1),(_vm.isTrialEndsAtNotified)?_c('h4',{staticClass:"trial-expire-alert"},[_c('v-icon',{staticClass:"my-icon-style"},[_vm._v(" mdi-alert ")]),_vm._v(" "+_vm._s(_vm.trialEndsAtNotification.event_name)+" "),_c('v-btn',{staticClass:"my-btn-style",attrs:{"small":""},on:{"click":function($event){return _vm.subscribe()}}},[_vm._v(" Subscribe ")])],1):_vm._e(),(_vm.isPlanExpired)?_c('h4',{staticClass:"trial-expire-alert"},[_c('v-icon',{staticClass:"my-icon-style"},[_vm._v(" mdi-alert ")]),_vm._v(" Your Subscriptions has expired "),_c('v-btn',{staticClass:"my-btn-style",attrs:{"small":""},on:{"click":function($event){return _vm.subscribe()}}},[_vm._v(" Subscribe ")])],1):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"mx-3"}),(_vm.userOrganizationCategoryCheck('advertiser'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":"","color":"#37474F","to":"/payments/products-cart"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"bordered":"","overlap":"","color":"#FF3700","value":_vm.productsCart.length > 0},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.productsCart.length))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-cart-outline ")])],1)],1)]}}],null,false,2326582702)},[_c('span',[_vm._v("Your Cart")])]):_vm._e(),(_vm.roleCheck('userAdmin'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":"","color":"#37474F","to":"/settings","disabled":_vm.isPlanExpired}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-cog-outline ")])],1)]}}],null,false,2892745216)},[_c('span',[_vm._v("Settings")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":"","color":"#37474F","to":"/pages/dashboard","disabled":_vm.isPlanExpired}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-view-dashboard ")])],1)]}}])},[_c('span',[_vm._v("Dashboard")])]),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":"","origin":"top left","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onToolTip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":"","color":"#37474F"},on:{"click":function($event){return _vm.clearUnRead()}}},'v-btn',attrs,false),Object.assign({}, onMenu, onToolTip)),[_c('v-badge',{attrs:{"color":"red","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.unReadCount))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-bell ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Notifications")])])]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.notifications),function(n,i){return _c('app-bar-item',{key:("item-" + i)},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(n.event_name)}})],1)}),1)])],1),_c('div',{staticClass:"mx-3"}),_c('v-menu',{attrs:{"bottom":"","rounded":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onToolTip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.userProfilePage()}}},Object.assign({}, onMenu, onToolTip)),[_c('v-avatar',{staticClass:"my-avatar-style",attrs:{"color":"#37474F","size":"33"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.firstName)+_vm._s(_vm.lastName))])])],1)]}}],null,true)},[_c('span',[_vm._v("Profile")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }